import {LoadingDialog} from '@eon.cz/apollo13-frontend-common/lib/components';
import {NotificationsComponent} from '@eon.cz/apollo13-frontend-common/lib/notification';
import * as React from 'react';
import {Fragment} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useSelector} from 'react-redux';
import {Lang} from '../../../Lang';
import {Store} from '../../../Store';
import {useAuthActionCreator} from '../actions';
import {LoginPanel} from '../components';
import {ErrorFallbackLogin} from '../components/ErrorFallbackLogin';

export const LoginContainer: React.FC = () => {
    const {closeNotification} = useAuthActionCreator();
    const {loginInProgress} = useSelector((state: Store) => state.auth);
    const {notifications} = useSelector((state: Store) => state.notifications);

    return (
        <Fragment>
            <ErrorBoundary FallbackComponent={ErrorFallbackLogin}>
                <LoginPanel />
                <NotificationsComponent notifications={notifications} onClose={closeNotification} />
                <LoadingDialog open={loginInProgress} description={Lang.LOGIN_LOADING} />
            </ErrorBoundary>
        </Fragment>
    );
};
