import {PaperBlock} from '@eon.cz/apollo13-frontend-common/lib/components';
import {AppBar, makeStyles, Theme, Typography} from '@material-ui/core';
import * as React from 'react';
import {FallbackProps} from 'react-error-boundary';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        'min-height': '100vh',
    },
    errorAppBar: {
        padding: theme.spacing(2),
    },
    errorWrapper: {
        padding: `64px ${theme.spacing()}px ${theme.spacing()}px`,
    },
}));

type Props = FallbackProps & {
    readonly error?: Error;
    readonly componentStack?: string;
};

export const ErrorFallbackLogin: React.FC<Props> = ({error, componentStack}) => {
    const classes = useStyles({});
    return (
        <div className={classes.errorWrapper}>
            <AppBar className={classes.errorAppBar}>
                <Typography variant="h6" color="inherit">
                    <FormattedMessage id={Lang.LOGIN_ERROR} />
                </Typography>
            </AppBar>

            <PaperBlock titleBlock={!!error ? error.message : undefined} topLineColor="error" collapse>
                <pre>{componentStack}</pre>
            </PaperBlock>
        </div>
    );
};
