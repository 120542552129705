import {Theme, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';

declare const window: any;

type Props = {
    readonly children: ReactNode;
};

type ClassesProps = {
    readonly backgroundImage: string;
    readonly height: number;
};

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    }
    if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'mobile';
    }
    return 'desktop';
};

const device = (window: Window) => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const dev = getDeviceType();
    switch (dev) {
        case 'desktop':
            if (height > 2100) {
                return "url('/static/images/EGD_Admin_layout_portrait.svg')";
            }
            return "url('/static/images/EGD_Admin_layout_landscape.svg')";
        case 'mobile':
            return "url('/static/images/EGD_admin_mobile.svg')";
        case 'tablet':
            if (width < 800) {
                return "url('/static/images/EGD_admin_ipad_portrait.svg')";
            }
            return "url('/static/images/EGD_admin_ipad_landscape.svg')";
    }
};

const useStyles = makeStyles<Theme, ClassesProps>((theme: Theme) => ({
    wrapper: ({backgroundImage}) => ({
        backgroundImage: backgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: theme.palette.common.white,
        height: '100vh',
        width: '100%',
        backgroundSize: '100% 100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(4)}px 0`,
    }),
    paper: ({height}) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: theme.spacing(7),
        zoom: height < 700 ? '95%' : undefined,
    }),
    main: {
        width: 'auto',
        display: 'flex',
        margin: 'auto',
    },
    nadpis: ({height}) => ({
        marginTop: height > 900 ? theme.spacing(1) : theme.spacing(15),
        color: theme.palette.common.white,
    }),
}));

/**
 * Wrapper komponenta pro přihlášení a reset hesla
 */
export const LandingLayout: React.FC<Props> = ({children}) => {
    const [backgroundImage, setBackgroundImage] = useState<string>('');
    const [height, setHeight] = useState<number>(1000);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setBackgroundImage(device(window));
            setHeight(window.innerHeight);
        }
    }, []);

    const classes = useStyles({backgroundImage, height});
    const variant = height > 900 ? 'h1' : 'h2';
    return (
        <div className={classes.wrapper}>
            <div role="main" className={classes.main}>
                <div className={classes.paper}>
                    <Typography className={classes.nadpis} variant={variant}>
                        <FormattedMessage id={Lang.PORTAL} />
                    </Typography>
                    {backgroundImage && children}
                </div>
            </div>
        </div>
    );
};
