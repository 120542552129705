import {isNotNullOrUndefinedOrEmpty} from '@eon.cz/apollo13-validations/lib/utils/UndefinedUtils';
import {Button, Grid, makeStyles, Theme} from '@material-ui/core';
import getConfig from 'next/config';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {LandingLayout} from '../../Layout/components';

const {publicRuntimeConfig} = getConfig();

const useStyles = makeStyles((theme: Theme) => ({
    submit: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.secondary.main,
        opacity: 1,
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(3),
        '&:disabled': {
            color: theme.palette.common.white,
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.common.white,
        },
    },
}));

export const LoginPanel: FC = () => {
    const classes = useStyles({});
    const {push} = useRouter();
    const handleLogin = () => {
        if (isNotNullOrUndefinedOrEmpty(publicRuntimeConfig.SARA_AUTHORIZATION_ENDPOINT)) {
            const uri = `${publicRuntimeConfig.SARA_AUTHORIZATION_ENDPOINT}?response_type=code&client_id=${publicRuntimeConfig.SARA_CLIENT_ID}&redirect_uri=${publicRuntimeConfig.SARA_OAUTH_REDIRECT_URL}&scope=openid`;
            push(uri);
        }
    };

    return (
        <LandingLayout>
            <Grid spacing={5} item container justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                    <Button onClick={handleLogin} fullWidth variant="contained" size="large" color="secondary" className={classes.submit}>
                        <FormattedMessage id={Lang.LOGIN_LOGIN} />
                    </Button>
                </Grid>
            </Grid>
        </LandingLayout>
    );
};
